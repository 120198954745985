/*

===================
* DIENN'S LAW
===================

* Coded by Jonathan Sunassee

* Template copyright below

*/

/*!

=========================================================
* BLK Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/blk-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footer/Footer.js";
import {ReactComponent as ReactLogo} from "../../logoTab.svg";

class ConveyancingPricing extends React.Component {


    componentDidMount() {
         window.scrollTo(0, 0);
        document.body.classList.toggle("conveyancing-pricing");
    }

    componentWillUnmount() {
        document.body.classList.toggle("conveyancing-pricing");
        document.documentElement.removeEventListener(
            "mousemove",
            this.followCursor
        );
    }

    followCursor = event => {
        let posX = event.clientX - window.innerWidth / 2;
        let posY = event.clientY - window.innerWidth / 6;
        this.setState({
            squares1to6:
                "perspective(500px) rotateY(" +
                posX * 0.05 +
                "deg) rotateX(" +
                posY * -0.05 +
                "deg)",
            squares7and8:
                "perspective(500px) rotateY(" +
                posX * 0.02 +
                "deg) rotateX(" +
                posY * -0.02 +
                "deg)"
        });
    };

    render() {

        //Todo refactor lists and convert all to consts

        const saleList = [
            {description: 'Initial instructions and advice', key: 0},
            {description: 'Obtaining the deeds', key: 1},
            {description: 'Contact lender for redemption statement (if applicable)', key: 1},
            {description: 'Collate and draft contract documents', key: 2},
            {description: 'Provide limited replies to enquiries raised by buyer\'s solicitor', key: 3},
            {description: 'Arrange for a new Will and Lasting Power of Attorney(s) (if applicable)', key: 4},
            {description: 'Sign contract, transfer, Will, Lasting Power of Attorney(s) (if applicable) and any other ' +
                    'documents ready to exchange', key: 5},
            {description: 'Agree a completion date', key: 6},
            {description: 'Exchange contracts', key: 7},
            {description: 'Obtain up to date redemption statement (if applicable)', key: 8},
            {description: 'Complete.', key: 9}];

        const purchaseList = [
            {description: 'Initial instructions and advice', key: 0},
            {description: 'Evidence funds are ready to purchase and contact the lender (if applicable)', key: 1},
            {description: 'Receive and review contract documents', key: 2},
            {description: 'Carry out searches (if applicable)', key: 3},
            {description: 'Obtain planning documents', key: 4},
            {description: 'Raise necessary limited enquiries to the seller\'s solicitor', key: 5},
            {description: 'Provide you with advice on documents and replies to enquiries received', key: 6},
            {description: 'Provide advice on the conditions of the mortgage offer (if applicable)', key: 7},
            {description: 'Provide you with a draft Stamp Duty Land Tax / Land Transaction Tax return form to discuss ' +
                    'with your accountant', key: 8},
            {description: 'Arrange for a new Will and Lasting Power of Attorney(s) (if applicable)', key: 9},
            {description: 'Agree a completion date', key: 10},
            {description: 'Report to your lender (if applicable)', key: 11},
            {description: 'Exchange', key: 12},
            {description: 'Arrange for completion monies from your lender (if applicable) and you', key: 13},
            {description: 'Complete', key: 14},
            {description: 'Submit and pay Stamp Duty Land Tax / Land Transaction Tax (if applicable)', key: 15},
            {description: 'Submit applications for registration at Land Registry', key: 16},
            {description: 'Return any deeds to your lender or you as applicable.', key: 17}];

        const transactionIncreaseList = [
            {description: 'Newbuild Houses or flats', key: 0},
            {description: 'Unregistered Land', key: 1},
            {description: 'Freeholds with management Companies', key: 2},
            {description: 'Help to Buy Loans or ISAS', key: 3},
            {description: 'Trust Deeds', key: 4},
            {description: 'Separately represented mortgage lenders', key: 5},
            {description: 'Transfers of part of a title', key: 6},
            {description: 'Listed Buildings', key: 7},
            {description: 'Urgency of the Transaction', key: 8},
            {description: 'Shared Ownership', key: 9},
            {description: 'Agree a completion date', key: 10},
            {description: 'Gifts', key: 11},
            {description: 'Abortive transactions', key: 12},
            {description: 'Delays', key: 13},
            {description: 'Complete', key: 14},
            {description: 'Indemnity policies', key: 15},
            {description: 'Third party involvement', key: 16},
            {description: 'Lifetime ISA.', key: 17}]

        return (
            <>

                <IndexNavbar/>
                <div className="page-header header-filter">
                    <div className="logo">
                         <a href="/home">
                        <ReactLogo />
                        </a>
                    </div>
                <div className="wrapper">
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="12" md="6">

                                <h5 className="text-on-back">Conveyancing (Selling & Purchasing) </h5>
                                <p>At Dienn’s Law Solicitors we pride ourselves on being trusted professional advisers to
                                    individuals and businesses. We always aim to ensure people have accurate and
                                    relevant information about us and their solicitor when they are
                                    considering purchasing legal services.</p>
                                <br/>
                                <p>From 6 December 2018, we are required to publish information on our website relating
                                    to residential conveyancing. </p>
                                <br/>
                                <p>Whilst we endeavour to provide information that is as accurate as possible, the
                                    pricing details shown on our website are examples and should only be used as a
                                    guide as pricing may vary depending on each individual circumstance. Every client
                                    will receive a client care letter and terms of business setting out our costs
                                    based on their needs and the specific facts of the service they require. </p>
                                <br/>
                                <h5 className="text-on-back">Price Information</h5>

                                <div style={{overflow: 'auto'}}>

                                <table className="table" >
                                    <thead>
                                    <tr>

                                        <th></th>
                                        <th>Freehold <br/>Sale</th>
                                        <th>&nbsp;</th>
                                        <th>Freehold <br/> Purchase</th>
                                        <th>&nbsp;</th>
                                        <th>Leasehold <br/>Sale</th>
                                        <th>&nbsp;</th>
                                        <th>Leasehold<br/>Purchase</th>
                                        <th>&nbsp;</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Value</td>
                                        <td>Minimum</td>
                                        <td>Maximum</td>
                                        <td>Minimum</td>
                                        <td>Maximum</td>
                                        <td>Minimum</td>
                                        <td>Maximum</td>
                                        <td>Minimum</td>
                                        <td>Maximum</td>
                                    </tr>
                                    <tr>
                                        <td>Up to £150,000</td>
                                        <td>£875</td>
                                        <td>£975</td>
                                        <td>£925</td>
                                        <td>£1,025</td>
                                        <td>£1,275</td>
                                        <td>£1,575</td>
                                        <td>£1,325</td>
                                        <td>£1,625</td>
                                    </tr>
                                    <tr>
                                        <td>£150,001 - £300,000</td>
                                        <td>£975</td>
                                        <td>£1,275</td>
                                        <td>£1,025</td>
                                        <td>£1,325</td>
                                        <td>£1,575</td>
                                        <td>£1,875</td>
                                        <td>£1,625</td>
                                        <td>£1,925</td>
                                    </tr>
                                    <tr>
                                        <td>£300,001 - £500,000</td>
                                        <td>£1,275</td>
                                        <td>£1,575</td>
                                        <td>£1,325</td>
                                        <td>£1,625</td>
                                        <td>£1,875</td>
                                        <td>£2,175</td>
                                        <td>£1,925</td>
                                        <td>£2,225</td>
                                    </tr>
                                    <tr>
                                        <td>£500,001 - £700,000</td>
                                        <td>£1,575</td>
                                        <td>£1,875</td>
                                        <td>£1,625</td>
                                        <td>£1,925</td>
                                        <td>£2,175</td>
                                        <td>£2,475</td>
                                        <td>£2,225</td>
                                        <td>£2,525</td>
                                    </tr>
                                    <tr>
                                        <td>£700,001 - £900,000</td>
                                        <td>£1,875</td>
                                        <td>£2,175</td>
                                        <td>£1,925</td>
                                        <td>£2,225</td>
                                        <td>£2,475</td>
                                        <td>£2,775</td>
                                        <td>£2,525</td>
                                        <td>£2,825</td>
                                    </tr>
                                    <tr>
                                        <td>£900,001 - £1,100,000</td>
                                        <td>£2,175</td>
                                        <td>£2,475</td>
                                        <td>£2,225</td>
                                        <td>£2,525</td>
                                        <td>£2,775</td>
                                        <td>£3,075</td>
                                        <td>£2,825</td>
                                        <td>£3,125</td>
                                    </tr>
                                    <tr>
                                        <td>£1,100,001 - £1,250,000</td>
                                        <td>£2,475</td>
                                        <td>£2,775</td>
                                        <td>£2,525</td>
                                        <td>£2,825</td>
                                        <td>£3,075</td>
                                        <td>£3,375</td>
                                        <td>£3,125</td>
                                        <td>£3,525</td>
                                    </tr>
                                    <tr>
                                        <td>£1,250,001 - £1,500,000</td>
                                        <td>£2,775</td>
                                        <td>£3,075</td>
                                        <td>£2,825</td>
                                        <td>£3,125</td>
                                        <td>£3,375</td>
                                        <td>£3,675</td>
                                        <td>£3,525</td>
                                        <td>£3,825</td>
                                    </tr>
                                    <tr>
                                        <td>Over £1,500,001 <p><a href="/contact-us"><b> Contact us</b></a></p><p>for
                                            more </p><p>information</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                                </div>


                                <p>All of our above fees are subject to VAT and disbursements (commonly known as
                                    out of pocket expenses). However, the fees
                                    include dealing with registration of the registered land at Land Registry and
                                    payment of Stamp Duty Land Tax, if the Property is in England or the Land
                                    Transaction Tax if the Property is in Wales.</p>

                                <p><b>Likely Disbursements:</b></p>
                                <p><b>Purchases:</b></p>
                                <ul>
                               <li> <p> Bank transfer fee of £42 inclusive of VAT per transfer.</p></li>

                                <li><p>Stamp Duty Land Tax / Land Transaction Tax:  	This is variable depending on your
                                    personal circumstances and the purchase price of the Property. We charge £50 plus
                                    VAT to draft your SDLT form for you accountant and submit it on your behalf. We
                                    incur a £6 inclusive of VAT fee. For a more accurate
                                    figure, if your Property is within England,
                                    please visit <a href="https://www.tax.service.gov.uk/calculate-stamp-duty-land-tax/#/intro">
                                        https://www.tax.service.gov.uk/calculate-stamp-duty-land-tax/#/intro
                                    </a> or if your
                                    Property is in Wales please visit
                                    <a href=" https://beta.gov.wales/land-transaction-tax-calculator"> https://beta.gov.wales/land-transaction-tax-calculator</a></p></li>
                                <li><p>Land Registry Fees:	£20-£1,105 on a scale determined by Land Registry. Please
                                    visit <a href="https://landregistry.data.gov.uk/fees-calculator.html">
                                        https://landregistry.data.gov.uk/fees-calculator.html</a></p></li>
                                <li><p>Searches:	It varies depending on which local authority that your Property is within.
                                    The usual searches obtained are Local Authority, Water and Drainage, Chancel,
                                    Environmental, and Highways. You should estimate between £300-£600 plus VAT, however this
                                    may vary.</p></li>
                                      <li><p>Bankruptcy Search:	£22.80 inclusive of VAT per person for 2 searches at £11.40 inclusive of VAT for each search.</p></li>
                                      <li><p>Land Registry Priority Search: £10.20 inclusive of VAT per title per search.</p></li>
                                      <li><p>SIM Search: £11.40 inclusive of VAT for up to 5 titles</p></li>
                                <li><p>Notice Fees: £20-£400 depending on the terms of your Lease or freehold management
                                    company.</p></li>
                                    <li><p>Electronic Verification of ID: £11 inclusive of VAT per person.</p></li>
                                    <li><p>Electronic Verification of Source of Funds: £4.80 inclusive of VAT per person.</p></li>
                                    <li><p>Electronic Document Signing: £7.80 inclusive of VAT per person.</p></li>
                                    <li><p>Submission of applications at Land Registry: £6 inclusive of VAT.</p></li>
                                    <li><p>Submission of Stamp Duty Land Tax or Land Transaction Tax: £6 inclsuive of VAT.</p></li>
                                    <li><p>Infotrack Fee for receipt of an LPA Mortgage Offer: £1.20 inclusive of VAT, if applicable.</p></li>
                                    <li><p>LMS Mortgage Offer Portal per Mortgage Offer: £42 inclusive of VAT, if applicable.</p></li>
                                    <li><p>There may be other disbursements such as obtaining indemnity policies and planning
                                        documents from the local authority.</p></li>
                                    <li><p>Anti-Money Laundering Check: £7.80 inclusive of VAT.</p></li>
                                    <li><p>Company House Search Fee: £42 inclusive of VAT, if applicable.</p></li>
                                    <li><p>Company House MR01 Registration Fee: £15-£23, if applicable.</p></li>
                                </ul>

                                <b>Sales:</b>
                                    <ul>
                                        <li><p>Estate Agent Fees: It varies depending on the agreement that you have made
                                    with your estate agents and can often range between 0.5%-5% of the Sale Price.</p></li>
                                        <li><p>Managing Agent Fees: It varies depending on the managing agents, however usually
                                    they range between £100-£500 plus VAT.</p></li>
                                        <li><p>Bank transfer fee of £42 inclusive of VAT per transfer.</p></li>
                                        <li><p>Office Copy entries from Land Registry: £10.20 inclusive of VAT per document.</p></li>
                                        <li><p>Electronic Verification of ID: £11 inclusive of VAT per person.</p></li>
                                        <li><p>Electronic Verification of Source of Funds: £4.80 inclusive of VAT per person.</p></li>
                                        <li><p>Electronic Document Signing - £7.80 inclusive of VAT per person.</p></li>
                                        <li><p>Bankruptcy Search: £22.80 inclusive of VAT per person for 2 searches at £11.40 inclusive of VAT for each search.</p></li>
                                        <li><p>There may be other disbursements such as obtaining indemnity policies and planning
                                    documents from the local authority.</p></li>
                                        <li><p>Anti-Money Laundering Check: £7.80 inclusive of VAT.</p></li>
                                        <li><p>Company House Search Fee: £42 inclusive of VAT, if applicable.</p></li>
                                    </ul>

                                <p>Likely reasons fees may be increased:
                                There are certain transactions whereby the above estimated fees may be amended for
                                    unforeseen matters or complex matters such as:</p>


                                    <ol>
                                        {transactionIncreaseList.map(transaction => {
                                            return (
                                                <li key={transaction.key}>{transaction.description}</li>
                                            );
                                        })}
                                    </ol>

                                <p>Please note that this list is not exhaustive.</p>
                                <br/>
                                <p>Re-mortgages are usually between £850-£1,500 plus VAT and disbursements.</p>
                                <br/>
                                <p>If work is carried out on an hourly rate, this will be up to £350 plus VAT.</p>
                                <br/>
                                <p><b>Likely Timescales: </b>
                                    This varies from transaction to transaction, however on average it is usually 8-16
                                    weeks. Often the timing scales can differ depending on how long the chain is,
                                    whether the Property is a freehold, leasehold, newbuild or if there is a mortgage.
                                    Sometimes some transactions can range between 2-8 months, if not longer, and others may be between
                                    2-6 weeks depending on a number of factors.</p>
                            <br/>
                                <h4><b>Key Stages: </b></h4>
                                <b>Sale: </b>

                                <ol>
                                    {saleList.map(sale => {
                                        return (
                                            <li key={sale.key}>{sale.description}</li>
                                        );
                                    })}
                                </ol>




                                <b>Purchase:</b>
                                <ol>
                                    {purchaseList.map(purchase => {
                                        return (
                                            <li key={purchase.key}>{purchase.description}</li>
                                        );
                                    })}
                                </ol>

                                <p><b>Whom will carry out your work:</b></p>
                                <p>Work will be carried out or supervised by an experienced solicitor.</p>
                                <br/>

                                <p>If you have any questions about our service and pricing, please call a member of the
                                    team where we will be happy to talk you through the options on
                                    <a href="tel:01403 379919"> 01403 379919</a> or
                                    email us on  <a href="mailto:info@diennslaw.co.uk"> info@diennslaw.co.uk.</a>
                                </p>

                                <p>
                                    Other Areas of Law:
                                    <ul>
                                        <a href="/agricultural"><li><u><b>Other Agricultural & Rural Property:</b></u></li></a>
                                        <a href="/business-and-company-law"><li><u><b>Business Services & Company Law - Corporate</b></u></li></a>
                                        <a href="/commercial-property"><li><u><b>Commercial Property</b></u></li></a>
                                        <a href="/debt-recovery"><li><u><b>Debt Recovery</b></u></li></a>
                                        <a href="/financial"><li><u><b>Financial</b></u></li></a>
                                        <a href="/landlord-and-tenant"><li><u><b>Landlord & Tenant - Leases</b></u></li></a>
                                        <a href="/lpas"><li><u><b>Power of Attorney(s) - LPA's & EPA's</b></u></li></a>
                                        <a href="/probate"><li><u><b>Probate, Intestacy, Deputyship & Court of Protection</b></u></li></a>
                                        <a href="/residential-conveyancing"><li><u><b>Residential Conveyancing</b></u></li></a>
                                        <a href="/wills-and-trusts"><li><u><b>Wills & Trusts</b></u></li></a>
                                    </ul>
                                </p>


                            </Col>
                        </Row>
                    </Container>
                </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default ConveyancingPricing;
